import React from "react";
import { Route, Redirect } from "react-router-dom";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const RouteWithLayout = (props: any) => {
  const { layout: Layout, location, component: Component, ...rest } = props;

  return (
    <Route
      {...rest}
      render={(matchProps): JSX.Element => {
        return (
          <Layout>
            <Component {...matchProps} />
          </Layout>
        );
      }}
    />
  );
};

export default RouteWithLayout;
