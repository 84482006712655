import React, { useEffect, useState } from "react";
import GhostContentAPI, { PostOrPage } from "@tryghost/content-api";
import { useParams } from "react-router-dom";
import formatPublishDate from "../../../utils/date";

const api = new GhostContentAPI({
  url: "https://ghost.dhruvikparikh.com",
  key: "f26be64c32eab7550f01251c55",
  version: "v2",
});

const Article = () => {
  const [post, setPost] = useState<PostOrPage>();
  let { slug } = useParams<{ slug: string }>();
  useEffect(() => {
    api.posts
      .read({ slug: slug }, { formats: ["html", "plaintext"] })
      .then((post) => {
        setPost(post);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  if (!post) return <></>;

  return (<div style={{paddingBottom: 32}}>
      <span style={{fontSize: 36, fontWeight: 700}}>{post.title as string}</span>
      <div style={{paddingTop: 12}}>
        <span style={{color: 'grey'}}>{formatPublishDate(post.published_at as string)}</span>
      </div>
      <div dangerouslySetInnerHTML={{ __html: post.html as string }}/>
    </div>);
};

export default Article;
