import React from "react";
import Navbar from "../components/Navbar";

interface MainLayoutProps {
  children: JSX.Element;
}

const MainLayout = ({ children }: MainLayoutProps) => {
  return (
    <div
      style={{
        width: window.innerWidth > 450 ? "70vw" : "90vw",
        height: "100vh",
        margin: "auto",
      }}
    >
      <Navbar />
      {children}
    </div>
  );
};

export default MainLayout;
