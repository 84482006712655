import React from "react";
import "./App.css";
import { Switch, Router } from "react-router-dom";
import Home from "./pages/Home/Home";
import { createBrowserHistory } from "history";
import RouteWithLayout from "./components/RouteWithLayout";
import MainLayout from "./layouts/Main";
import Blog from "./pages/Blog/Blog";
import Article from "./pages/Blog/components/Article";
//import Resume from "./pages/Resume/Resume";

const history = createBrowserHistory();

function App() {
  return (
    <Router history={history}>
      <Switch>
        <RouteWithLayout
          path="/writing/:slug"
          component={Article}
          layout={MainLayout}
        />
        <RouteWithLayout path="/writing" component={Blog} layout={MainLayout} />
        <RouteWithLayout
          path="/essays/:slug"
          component={Article}
          layout={MainLayout}
        />
        <RouteWithLayout path="/essays" component={Blog} layout={MainLayout} />
        {/* <RouteWithLayout
          path="/resume"
          component={Resume}
          layout={MainLayout}
        /> */}
        <RouteWithLayout path="/" component={Home} layout={MainLayout} />
      </Switch>
    </Router>
  );
}

export default App;
