import React from "react";

const sections = [
  { label: "Home", href: "/home" },
  { label: "Writing", href: "/writing" },
];

const Navbar = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        height: "40px",
        padding: "20px",
        fontSize: "24px",
      }}
    >
      {sections.map((item, idx) => (
        <div
          style={idx === 0 ? { marginLeft: "auto" } : { paddingLeft: "20px" }}
        >
          <a href={item.href}>{item.label}</a>
        </div>
      ))}
    </div>
  );
};

export default Navbar;
