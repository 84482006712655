import React, { useEffect, useState } from "react";
import GhostContentAPI, { PostOrPage } from "@tryghost/content-api";
import formatPublishDate from "../../utils/date";

const api = new GhostContentAPI({
  url: "https://ghost.dhruvikparikh.com",
  key: "f26be64c32eab7550f01251c55",
  version: "v2",
});

const Blog = () => {
  const [articles, setArticles] = useState<PostOrPage[]>([]);
  useEffect(() => {
    api.posts.browse({ filter: "tag: blog" }).then((posts) => {
      setArticles(posts);
    });
    return () => {};
  }, []);

  return (
    <div>
      <h1>Writing</h1>
      <p>{"[Work in progress]"}</p>
      {articles.map((art) => (
        <div style={{paddingBottom: 4}}>
          <a href={"/writing/" + art.slug}>{art.title || art.slug}</a>
          {" "}
          <span style={{color: 'grey'}}>{formatPublishDate(art.published_at as string)}</span>
        </div>
      ))}
    </div>
  );
};

export default Blog;
