import React from "react";
import Link from "../../components/Link";

const Home = () => {
  console.log(window.innerWidth);
  return (
    <div style={{ lineHeight: 1.5 }}>
      <div
        style={{
          maxWidth: "100vw",
          margin: "0 auto",
          display: "flex",
          flexDirection: window.innerWidth > 800 ? "row" : "column",
        }}
      >
        <div>
          <img
            src="dhruvik_square.jpg"
            style={{
              borderRadius: "50%",
              maxHeight: window.innerWidth > 450 ? "300px" : undefined,
              maxWidth: window.innerWidth > 450 ? "300px" : undefined,
              height: window.innerWidth > 450 ? undefined : "70vw",
              width: window.innerWidth > 450 ? undefined : "70vw",
              paddingRight: "16px",
            }}
            alt="Dhruvik Parikh"
          />
        </div>
        <div>
          <h1>Hi, I'm Dhruvik!</h1>
          <p>
            I'm a 22-year-old programmer and college student based in New York City. 
            I recently graduated from Stanford University where I majored in Computer
            Science and explored Philosophy, History, and Sociology on the
            side. For the past three years, I've been working on
            Engineering and Product at{" "}
            <Link href="https://kalshi.com">Kalshi</Link>. Outside of work and
            school, I like to learn about random topics (see below) and
            sometimes build projects. I recently started a <Link href="/essays/hello-world">writing project</Link> for 2023. I love meeting new people and potential
            collaborators, so don't hesitate to reach out if anything on here is
            interesting to you!
            <br></br>
            <br></br>
            You can reach me on{" "}
            <Link href="https://twitter.com/DhruvikSP">
              Twitter
            </Link> or <Link href="mailto:dhruvik@alumni.stanford.edu">Email</Link>
          </p>
          <div
            style={{
              maxWidth: "100%",
              margin: "0 auto",
            }}
          >
            <div>
              <h2>I'm currently learning about</h2>
              <ul>
                <li>The semiconductor industry</li>
                <li>Economics of electricity</li>
                <li>Growth hacking</li>
              </ul>
            </div>
            <div>
              <h2>Next up on my learning bucket list</h2>
              <ul>
                <li>How the global supply chain works</li>
                <li>Molecular gastronomy</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
