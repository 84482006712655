interface LinkProps {
  href: string;
  children: any;
}

const Link = (props: LinkProps) => {
  return (
    <a href={props.href} target="_blank" rel="noopener noreferrer">
      {props.children}
    </a>
  );
};

export default Link;
